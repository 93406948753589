// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaUserSecret, FaEnvelopeOpen, FaTasks, FaWrench, FaCog } from 'react-icons/fa';


// eslint-disable-next-line no-unused-vars
const services = (props) => {
    return (
        <section id="services-area" className="bg-gray">
            <Container>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6} xs={12}>
                        <div className="section-heading text-center">
                            <h2>Soluciones</h2>
                            <p>Trabajamos directamente con Quadient, empresa que permite a otras empresas conectarse con personas para ofrecer grandes experiencias a través de comunicaciones omnicanal excepcionales, significativas y personalizadas.</p>
                        </div>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                <Row>
                    <Col sm={6} md={4}>
                        <Card body className="text-center services-card">
                            <FaCog />
                            <h4>Customer Communications Management</h4>
                            <p>¿Qué es el CCM?</p>
                            <p className="services-card-desc">Gracias a supermarcas como Apple, Google y Amazon, las expectativas del cliente son más altas que nunca. Los clientes de hoy exigen comunicaciones personalizadas y pertinentes que estén disponibles en tiempo real y sean accesibles a través del canal de su elección. Pero para las industrias muy reguladas como aquellas de servicios de seguros y financieros, la transformación digital presenta varios desafíos.</p>
                        </Card>
                    </Col>
                    <Col sm={6} md={4}>
                        <Card body className="text-center services-card">
                            <FaEnvelopeOpen />
                            <h4>Experiencia digital personalizada</h4>
                            <p>¿Qué es una plataforma <br/> de experiencia digital?</p>
                            <p className="services-card-desc">Las cuentas móviles y web de sus clientes contienen contenido altamente individualizado que incluye estados de cuenta personalizados, formularios precargados, facturas, información de contratos y más. Las empresas más exitosas también están aprovechando estos canales para mayores ventas y ventas cruzadas de productos y servicios nuevos.</p>
                        </Card>
                    </Col>
                    <Col sm={6} md={4}>
                        <Card body className="text-center services-card">
                            <FaTasks />
                            <h4>Customer Journey Mapping</h4><br />
                            <p>¿Qué son los mapas de recorrido?</p>
                            <p className="services-card-desc">Un mapa de recorrido del cliente es una ilustración paso a paso de cómo interactúan sus clientes con su organización. Se crea luego de realizar un recorrido en los zapatos de sus clientes mientras compran y consumen sus productos y servicios. Los mapas de recorrido destacan las necesidades del cliente, los obstáculos técnicos y las emociones en cada interacción con su compañía.</p>
                            <br />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default services;