// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarker, FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

import ContactForm from './contactForm';

// eslint-disable-next-line no-unused-vars
const contact = (props) => {
    return (
        <section id="contact-area" className="bg-white">
            <Container>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6}>
                        <div className="section-heading text-center">
                            <h2>Contáctenos</h2>
                            <p>Envíanos un correo con tus preguntas</p>
                        </div>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                <Row>
                    <Col md={3}></Col>
                    <Col sm={8} md={6}>
                        <div className="contact-form text-center">
                            <ContactForm />
                        </div>
                    </Col>
                    <Col sm={4} md={3}>
                        <div className="contact-detail">
                            <div className="contact-detail-item text-center">
                                <h6> <FaPhone /> Llámenos :</h6>
                                <p>(51) 1 652 2512 <br />(51) 1 647 4543</p>
                            </div>
                            <div className="contact-detail-item text-center">
                                <h6> <FaEnvelope /> Email :</h6>
                                <p>contacto@cxtech.pe <br /></p>
                            </div>
                            <div className="contact-detail-item text-center">
                                <h6> <FaMapMarker /> Ubícanos en :</h6>
                                <p>Manuel Scorza 137 La Molina 15012 <br/> Lima Perú</p>
                            </div>
                            <div className="social-media text-center">
                                <h6>Próximamente en :</h6>
                                <ul>
                                    <li><a><FaFacebook /></a></li>
                                    <li><a><FaTwitter /></a></li>
                                    <li><a><FaLinkedinIn /></a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default contact;