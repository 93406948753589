// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
const about = (props) => {
    return (
        <section id="about-area" className="bg-white">
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="about-content">
                            <h2>¿Quiénes Somos?</h2>
                            <br />
                            <p>Customer eXperience Technologies – CXTECH nace para convertirse en el aliado estratégico de las organizaciones y acompañarlas en el camino de la constante transformación digital contando con un equipo de profesionales con amplia experiencia en dichos procesos y la alianza estratégica con Quadient, que nos permite construir sobre una plataforma de experiencia digital de clase mundial; habilitando estrategias, equipos, procesos y tecnología con el cliente final en el centro de todo. </p>
                        </div>
                    </Col>
                    <Col sm={1}></Col>
                    <Col sm={5}>
                        <div className="about-img">
                            <img src="assets/laptop.jpg" className="img-fluid" alt="Image" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default about;