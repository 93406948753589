// eslint-disable-next-line no-unused-vars
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';

import Wrapper from './pages/wrapper/wrapper';

const App = () => (
  // <Provider store={store}>
  <Router>
    <div>
      <Route exact path="/" component={Wrapper} />
    </div>
  </Router>
  // </Provider>
);

export default App;
