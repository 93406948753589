// eslint-disable-next-line no-unused-vars
import React from 'react';

import Header from '../header/header';
import Home from '../home/home';
import About from '../about/about';
import Services from '../services/services';
import Contact from '../contact/contact';

const wrapper = (props) => {
    return (
        <div className="wrapper">
            <Header />
            <Home />
            <About />
            <Services />
            <Contact />
        </div>
    );
}

export default wrapper;