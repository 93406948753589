// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import { Form, Button, Alert } from 'react-bootstrap';


export default function () {
    const [nameClass, setNameClass] = useState('');
    const [emailClass, setEmailClass] = useState('');
    const [subjectClass, setSubjectClass] = useState('');
    const [messageBodyClass, setMessageBodyClass] = useState('');
    const [isSended, setIsSended] = useState(false);
    const { handleSubmit, register, errors } = useForm();

    const onSubmit = data => {
        const templateId = 'template_Y2z7d5fF';

        let templateParams = {
            from_name: data.name,
            reply_to: data.email,
            subject: data.subject,
            message_html: data.messageBody
        };

        sendEmail(templateId, templateParams);
    };

    const sendEmail = (templateId, templateParams) => {
        window.emailjs.send('gmailID', templateId, templateParams)
            .then(function (response) {
                //console.log('SUCCESS!', response.status, response.text);
                setIsSended(true);
            }, function (error) {
                console.log('FAILED...');
            });
    }

    useEffect(function () {
        if (errors.name) {
            setNameClass('error-style');
        } else {
            setNameClass('');
        }
        if (errors.email) {
            setEmailClass('error-style');
        } else {
            setEmailClass('');
        }
        if (errors.subject) {
            setSubjectClass('error-style');
        } else {
            setSubjectClass('');
        }
        if (errors.messageBody) {
            setMessageBodyClass('error-style');
        } else {
            setMessageBodyClass('');
        }
    });

    if (isSended) {
        return (<Alert variant="success">Su mensaje fue enviado con éxito!</Alert>);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <input type="text" placeholder="Nombre" className={'form-control ' + nameClass} name="name" ref={register({ required: true })} />
            </div>
            <div className="form-group">
                <input type="text" placeholder="Email" className={'form-control ' + emailClass} name="email" ref={register({
                    required: 'Email es requerido!',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Inválida dirección email!"
                    }
                })} />
                {/* {errors.email && <p className="text-danger">{errors.email.message}</p>} */}
            </div>
            <div className="form-group">
                <input type="text" placeholder="Asunto" className={'form-control ' + subjectClass} name="subject" ref={register({ required: true })} />
            </div>
            <div className="form-group">
                <textarea type="text" rows="5" className={'form-control ' + messageBodyClass} name="messageBody" ref={register({ required: true })} />
            </div>
            <button type="submit">Enviar</button>
        </form>
    );

}