// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Row, Col } from 'react-bootstrap';


// eslint-disable-next-line no-unused-vars
const home = (props) => {
    return (
        <section id="home-area" className="bg-gray">
            <Row className="no-margin">
                <img src="assets/banner_cxtech.png" alt="Image" />
                <Col lg={5} md={5} sm={12} xs={12}>
                    <p>
                        Hoy, cada interacción comercial, desde el correo hasta el comercio electrónico, es una oportunidad para <span>construir</span> una <span>conexión </span>
                        significativa y para crear un impacto en la vida de alguien.
                    </p>
                </Col>
            </Row>
        </section>
    );
}

export default home;