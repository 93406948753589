// eslint-disable-next-line no-unused-vars
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Scrollspy from "react-scrollspy";

// eslint-disable-next-line no-unused-vars
const header = props => {
	return (
		<header id="header">
			<Navbar className="bg-gray" expand="lg" id="headerCxtech">
				<Container>
					<Navbar.Brand href="#header">
						<img src="assets/logo_cxtech.png" className="navbar-brand-img" alt="Logo" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav>
							<Nav.Link href="#header">Inicio</Nav.Link>
							<Nav.Link href="#about-area">Nosotros</Nav.Link>
							<Nav.Link href="#services-area">Soluciones</Nav.Link>
							<Nav.Link href="#contact-area">Contacto</Nav.Link>
						</Nav>
					</Navbar.Collapse>
					{/* <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Scrollspy items={['home-area', 'about-area', 'services-area', 'contact-area']} currentClassName="active">
                            <li className="nav-link"><a href="#home-area">Inicio</a></li>
                            <li className="nav-link"><a href="#about-area">Nosotros</a></li>
                            <li className="nav-link"><a href="#services-area">Servicios</a></li>
                            <li className="nav-link"><a href="#contact-area">Contacto</a></li>
                        </Scrollspy>
                    </Navbar.Collapse> */}
				</Container>
			</Navbar>
		</header>
	);
};

export default header;
